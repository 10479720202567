export default class ConsentimentoService {
  constructor(http) {
    this._http = http
  }

  async getListaConsentimentos() {
    const { data } = await this._http.get(`/api/consentimentos/list`)
    return data
  }

  async getConsentimentos(dados) {
    const { data } = await this._http.get(
      `/api/consentimentos/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async getListaConsentimentosDoServidor() {
    const { data } = await this._http.get(`/api/consentimentos/servidor`)
    return data
  }

  async permitirConsentimento(consentimento) {
    let cons = {
      consignataria: { id: consentimento.idConsignataria },
      servidor: { id: consentimento.idServidor },
    }
    cons.ativo = true
    return await this._http.post('/api/consentimentos', cons)
  }

  async proibirConsentimento(consentimento) {
    return await this._http.delete(`/api/consentimentos/${consentimento.id}`)
  }

  async buscarConsentimentosPorNomeOuCnpj(nomeCNPJ) {
    return await this._http.get(
      `/api/consentimentos/buscar?nomeCNPJ=${nomeCNPJ}`,
    )
  }
}
