<template>
  <Card>
    <template #title>Consentimento</template>
    <template #content>
      <div>
        <div class="flex">
          <form @submit.prevent="onSubmit">
            <div class="p-inputgroup">
              <InputText
                id="nomeCNPJ"
                v-model="v$.nomeCNPJ.$model"
                autofocus
                :allowEmpty="true"
                :useGrouping="false"
                placeholder="CNPJ ou Nome Fantasia" />
              <Button class="p-button" type="submit">
                <span v-if="!loading" class="pi pi-search"></span>
                <span v-if="loading" class="pi pi-spin pi-spinner"></span>
                <span class="ml-2">Buscar</span>
              </Button>
              <Button
                class="ml-2 pr-3 p-button-secondary"
                icon="pi pi-filter-slash"
                label="Limpar"
                @click="limparFiltro()"></Button>
            </div>
            <div
              v-if="v$.nomeCNPJ.$model != null && v$.nomeCNPJ.$error"
              class="p-error">
              O campo deve possuir mais que 3 caracteres.
            </div>
          </form>
        </div>
        <DataTable
          class="p-datatable-sm"
          sortField="razaoSocial"
          :loading="loading"
          :sortOrder="1"
          :value="consentimentos"
          stripedRows
          responsiveLayout="stack">
          <template #empty> Nenhuma consignataria encontrada. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column field="ativo" header="Autorização">
            <template #body="{ data }">
              <Button
                v-if="data.ativo"
                label="AUTORIZADO"
                icon="pi pi-check"
                class="p-button-success md:col-12"
                @click="mudarAutorizacao(data)" />
              <Button
                v-else
                label="NÃO AUTORIZADO"
                icon="pi pi-times"
                class="p-button-danger md:col-12"
                @click="mudarAutorizacao(data)" />
            </template>
          </Column>
          <Column field="razaoSocial" header="Razão Social"></Column>
          <Column field="nomeFantasia" header="Nome Fantasia"></Column>
          <Column field="cnpj" header="CNPJ"></Column>
        </DataTable>
        <Dialog
          :visible.sync="display"
          modal
          header="Header"
          :style="{ width: '450px' }">
          <template #header>
            <strong>{{
              consentimento.ativo
                ? 'REMOVER CONSENTIMENTO'
                : 'AUTORIZAR CONSENTIMENTO'
            }}</strong>
          </template>
          <span v-if="consentimento.ativo"
            >Deseja <strong>REMOVER O CONSENTIMENTO </strong> da consignatária
            <strong>{{ consentimento.nomeFantasia }}</strong
            >, fazendo que a <strong>NÃO</strong> tenha acesso aos seus
            dados?</span
          >
          <span v-if="!consentimento.ativo"
            >Deseja <strong>AUTORIZAR</strong> que a consignatária
            <strong>{{ consentimento.nomeFantasia }}</strong> tenha acesso aos
            seus dados?</span
          >
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="display = false" />
            <Button
              label="Sim"
              icon="pi pi-check"
              autofocus
              @click="mudaEstadoConsentimento">
              <i class="pi pi-check mr-2" />
              <span v-if="!consentimento.ativo">AUTORIZAR</span>
              <span v-if="consentimento.ativo">REMOVER AUTORIZAÇÃO</span>
            </Button>
          </template>
        </Dialog>
      </div>
    </template>
  </Card>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, minLength } from 'vuelidate/lib/validators'
import ConsentimentoService from '@/service/ConsentimentoService.js'
import { servidorStore } from '@/stores/servidor'

export default {
  setup() {
    const store = servidorStore()
    return { store, v$: UseVuelidate() }
  },

  data() {
    return {
      loading: false,
      consentimentos: null,
      consentimento: {},
      statusConsentimentoDialog: false,
      display: false,
      nomeCNPJ: '',
    }
  },

  created() {
    this.consentimentoService = new ConsentimentoService(this.$http)
  },

  mounted() {
    this.loading = true
    this.consentimentoService.getListaConsentimentosDoServidor().then((res) => {
      this.loading = false
      this.consentimentos = res
    })
  },

  validations: {
    nomeCNPJ: {
      required,
      minLength: minLength(3),
    },
  },

  methods: {
    mudarAutorizacao(data) {
      this.display = true
      this.consentimento = data
    },

    mudaEstadoConsentimento() {
      this.display = false
      this.loading = true

      if (this.consentimento.ativo !== true) {
        this.consentimentoService
          .permitirConsentimento(this.consentimento)
          .then((res) => {
            this.loading = false
            let index = this.findIndexByIdConsignataria(
              res.data.consignataria.id,
            )
            this.consentimentos[index].ativo = res.data.ativo
            this.consentimentos[index].id = res.data.id
            this.$toast.add({
              severity: 'success',
              summary: 'Alterado com sucesso!',
              life: 10000,
            })
          })
          .catch((err) => {
            this.loading = false
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
      } else {
        this.consentimentoService
          .proibirConsentimento(this.consentimento)
          .then(() => {
            this.loading = false

            const index = this.consentimentos.findIndex(
              (element) =>
                element.razaoSocial === this.consentimento.razaoSocial,
            )

            this.consentimentos[index].ativo = false
            this.consentimentos[index].id = null
            this.$toast.add({
              severity: 'success',
              summary: 'Alterado com sucesso!',
              life: 10000,
            })
          })
          .catch((err) => {
            this.loading = false
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
      }
    },

    findIndexByIdConsignataria(id) {
      let index = -1
      for (let i = 0; i < this.consentimentos.length; i++) {
        if (this.consentimentos[i].idConsignataria === id) {
          index = i
          break
        }
      }
      return index
    },

    onSubmit: function () {
      this.v$.$touch()
      if (this.v$.$error) return
      this.buscarMatriculaCPF()
    },

    buscarMatriculaCPF() {
      if (this.nomeCNPJ) {
        this.removerMascaraCNPJ()
        this.loading = true
        this.consentimentoService
          .buscarConsentimentosPorNomeOuCnpj(this.nomeCNPJ)
          .then((res) => {
            this.loading = false
            this.consentimentos = res.data
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Informe o Nome ou o CNPJ.',
          life: 10000,
        })
      }
    },

    removerMascaraCNPJ() {
      const mascaraCNPJ = new RegExp(
        /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/,
      )

      if (mascaraCNPJ.exec(this.nomeCNPJ)) {
        this.nomeCNPJ = this.nomeCNPJ.replace(/\D/g, '')
      }
    },

    limparFiltro() {
      this.nomeCNPJ = null
      this.loading = true
      this.consentimentoService
        .getListaConsentimentosDoServidor()
        .then((res) => {
          this.loading = false
          this.consentimentos = res
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.p-togglebutton {
  background-color: #bf0202;
}

.p-highlight {
  background-color: #32b232 !important;
}
</style>
